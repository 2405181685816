<template>
  <div
    style="border-radius: 10px !important;"
    class="max-h item-group-kanban"
    :style="`background: ${colorGroup || '#333'}; opacity: 0.95; backdrop-filter: blur(10px) !important;`"
  >
    <v-list-item
      style="max-height: 25px; cursor: grab;"
      class="kanban-selector px-2 mx-0"
    >
      <v-list-item-content>
        <v-list-item-title>
          <v-icon color="#f2f2f2">
            mdi-grain
          </v-icon>
          <span style="font-size: 14pt;" class="fonte-bold white--text not-selectable">
            {{ group.name.includes('salvo') ? 'Últimos Pedidos' : group.name }} - ({{ list.length }})
          </span>
        </v-list-item-title>
        <v-list-item-subtitle>
          <!-- <span class="fonte grey--text not-selectable fonteMini"> itens</span> -->
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-menu v-if="!group.name.includes('salvo') " :close-on-content-click="false" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn x-small icon v-bind="attrs" v-on="on">
              <v-icon style="opacity: 0.3" color="white"
                >mdi-cog-outline</v-icon
              >
            </v-btn>
          </template>
          <v-card dark width="300">
            <v-color-picker
              dot-size="18"
              swatches-max-height="250"
              :swatches="swatches"
              show-swatches
              hide-canvas
              hide-mode-switch
              v-model="group.color"
              @input="saveColor(group)"
              hide-sliders
              hide-inputs
            ></v-color-picker>

            <v-list dense class="fonte pa-0 ma-0" color="transparent">
              <v-list-item @click="deleteKanban(group)">
                <v-list-item-title>Apagar Lista</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-list-item-action>
    </v-list-item>
    <div class="kanban-item-cont" style="min-height: 88vh;">
      <draggable
        v-for="(kanban, index) in kanbans"
        :key="kanban._id"
        :group="{ name: 'kanban-item' }"
        handle=".move-card-item"
        ghost-class="kanban-ghost-itemm"
        :group-name="group.name"
        :class="`group-sale-${group.name}`"
        @change="itemKanbanChange($event, kanban)"
        @end="itemKanbanChange($event, kanban)"
        style="border-radius: 10px !important;"
      >
        <SaleItem
          :data-index="index"
          :class="`sale-id-${kanban._id}`"
          :id="kanban._id"
          :data-id="kanban._id"
          :kanban="kanban"
        />
      </draggable>
      <draggable
        v-if="kanbans.length === 0"
        :group="{ name: 'kanban-item' }"
        :group-name="group.name"
        style="min-height: 70vh;"
      />
      <div
        class="expande-horizontal centraliza"
        style="height: 100px;"
        v-intersect="onEndOfList"
        v-if="totalToShow >= 6 && totalToShow < list.length"
      >
        <v-btn small @click="onEndOfList" block>Carregar mais</v-btn>
      </div>
      <div
        v-if="totalToShow >= list.length && list.length > 6"
        class="expande-horizontal centraliza"
        style="height: 100px;"
        v-intersect="onEndOfList"
      >
        <span class="fonte fonteMini">fim!</span>
      </div>
    </div>
  </div>
</template>

<script>
import SaleItem from "./SaleItem.vue";
import draggable from "vuedraggable";
import { EventBus } from "@/main.js";

export default {
  name: "ElementContainer",
  props: ["value", "list", "group", "itemKanbanChange", "deleteKanban"],
  components: {
    SaleItem,
    draggable
  },
  data() {
    return {
      swatches: [
        ["#F44336", "#EF5350", "#EF9A9A"],
        ["#9C27B0", "#AB47BC", "#CE93D8"],
        ["#2196F3", "#42A5F5", "#90CAF9"],
        ["#4CAF50", "#66BB6A", "#A5D6A7"],
        ["#FFEB3B", "#FFEE58", "#FFF59D"]
      ],
      totalToShow: 6,
      itemsUpdated: []
    };
  },
  computed: {
    kanbans() {
      const list = this.value || this.list;
      const totalToShow = this.totalToShow;
      if (list) {
        return list.slice(0, totalToShow);
        // return list;
      } else {
        return [];
      }
    },
    colorGroup() {
      if (this.group.color) {
        return this.group.color.hex;
      } else {
        return "#333";
      }
    }
  },
  mounted() {
    const self = this;
    EventBus.$on(this.group.name, itensUpdateds => {
      self.$emit("input", itensUpdateds);
      self.$emit("change", itensUpdateds);
      // self.value = itensUpdateds;
      self.$forceUpdate();
    });
  },
  methods: {
    onEndOfList() {
      this.totalToShow += 4;
    },
    updateLocalGroup(kanbanUpdated) {
      const kanbans = localStorage.getItem("kanbans");
      if (kanbans) {
        const kanbansParsed = JSON.parse(kanbans);
        kanbansParsed.map(kanban => {
          if (kanban._id === kanbanUpdated._id) {
            kanban = kanbanUpdated;
          }
        });
        localStorage.setItem("kanbans", JSON.stringify(kanbansParsed));
      }
    },
    saveColor(group) {
      let newGroup = {
        ...group,
        color: group.color
      };
      delete newGroup.kanbans;
      this.updateLocalGroup(newGroup);

      this.$run("kanban/update", newGroup)
        .then(() => {
          // this.$store.dispatch("createGlobalMessage", {
          //   type: "success",
          //   message: `Cor do grupo atualizada`,
          //   timeout: 6000
          // });
        })
        .catch(error => {
          this.$store.dispatch("createGlobalMessage", {
            type: "info",
            message: error.response.data.message,
            timeout: 6000
          });
        });
    }
  }
};
</script>

<style>
.max-h {
  border-radius: 10px !important;
  overflow: hidden;
  max-height: 88vh;
}

.item-group-kanban {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 10px !important;
  padding-right: 6px;
  margin-left: 6px;
  background: rgba(0, 0, 0, 0);
}
.kanban-item-cont {
  display: flex;
  width: 100%;
  overflow: auto;
  max-height: 80vh;
  padding-left: 6px;
  padding-right: 6px;
  flex-direction: column;
  background: rgba(0, 0, 0, 0);
}
.kanban-item-cont::-webkit-scrollbar {
  width: 8px;
  border-radius: 20px;
}

.kanban-item-cont::-webkit-scrollbar-track {
  background: rgba(243, 242, 242, 0.2);
  border-radius: 20px;
}

.kanban-item-cont::-webkit-scrollbar-thumb {
  background-color: #ffffffa8;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.kanban-ghost-itemm {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  border-radius: 10px !important;
  padding-right: 6px;
  overflow: hidden;
}
</style>
